<template></template>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  components: {},
  created() {
    if (window.location.href.indexOf("loginCode") == -1) {
      let domain = this.getDomain();
      window.location.replace(
        "https://trpt.jyzh56.com/transport_api" +
          "/transport/sso/login" +
          "?back=" +
          domain
      );
    } else {
      let domain = this.getOnlyDomain();
      console.log(domain);
      console.log(this.getParams());
      this.$http("api1", "/transport/sso/check", "post", {
        loginCode: this.getParams(),
      }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          localStorage.setItem("token", res.data.token);
          let obj = {
            token: res.data.token,
            username: res.data.userData.username,
            fleetManager: res.data.fleetManager,
          };
          this.adduser(obj);
          window.location.replace(domain + "/#/home/homepage");
        }
      });
    }
  },
  methods: {
    ...mapMutations(["adduser"]),
    getDomain() {
      const url = window.location.href; // 输出域名部分
      let end = url.indexOf("#");
      let domain = url.substring(0, end + 1);
      return domain;
    },
    getOnlyDomain() {
      const url = window.location.href; // 输出
      let end = url.indexOf("?");
      let domain = url.substring(0, end - 1);
      return domain;
    },
    getParams() {
      const url = window.location.href; // 输出域名部分
      let start = url.indexOf("=");
      let end = url.lastIndexOf("#");
      let params = url.substring(start + 1, end);
      return params;
    },
  },
};
</script>
